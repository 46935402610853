import DailyReportPage from "./app/command-center/components/dailyReportPage/dailyReportPage";

function App() {
  return (
    <div className="App">
      <DailyReportPage/>
    </div>
  );
}

export default App;

import React, { useState, useEffect }  from "react";
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { Form, FormGroup } from "react-bootstrap"

function dateComparison(a, b) {
  const date1 = new Date(a)
  const date2 = new Date(b)
  
  return date1 - date2;
}

function totalComparison(a, b) {
  return a.total - b.total;
}

const calculateClosedChartStat = (minorAssitance, incidents, resolved) => {

  let result = (resolved / (incidents)) * 100;

  return Math.ceil(result) ?? 0;
}

const DailyReportComponent = ({ title, hospitals,dispositions, spots, encounters, minorAssistance, reports, raceStationOpen,raceStationResolved,triageReportTitle,persons}) => {
  const [reportTitle, setReportTitle] = useState(triageReportTitle ?? 'Daily Report')
  const [uniquePersonsTotal, setuniquePersonsTotal] = useState(0)
  const [closedRecordsTotal, setClosedRecordsTotal] = useState(0)
  const [injuryTotal, setInjuryTotal] = useState(0)
  const [illnessTotal, setIllnessTotal] = useState(0)
  const [minorTotal, setMinorTotal] = useState(0)
  const [hideClosedRecords, setHideClosedRecords] = useState(false)
  const [summaryTotals, setSummaryTotals] = useState({
    dataDate:[],
    dispositions:[],

  });
  const [stationSummaryTotal, setStationSummaryTotal] = useState({})
  const countStyle = { "fontSize": 15 };

  const calculateMasterSummary = () => {
    Object?.keys(dispositions?.dispositionTable ?? []).forEach(race => {
      setuniquePersonsTotal(prevValue => {
        return prevValue + parseInt(persons.find(r => r.Race === race)?.Amount ?? 0)});
      setClosedRecordsTotal(prevValue => {
        return prevValue + (dispositions?.totals?.[race]?.total ?? 0)});
      setInjuryTotal(prevValue => {
        return prevValue + (spots?.spotTable?.[race]?.['Injury']?.total ?? 0)});
      setIllnessTotal(prevValue => {
        return prevValue + (spots?.spotTable?.[race]?.['Illness']?.total ?? 0)});
      setMinorTotal(prevValue => {
        return prevValue + (minorAssistance?.totals?.[race]?.total ?? 0)});

        //calculate station summary total for each race
      let SumTotalPerRace = {Total: 0, Active: 0, Red: 0, Yellow: 0, Green: 0, Minor: 0, Resolved: 0, Transport: 0};
      Object?.keys(reports?.races?.[race]?.statistics ?? []).forEach(station => {
        SumTotalPerRace.Active += parseInt(raceStationOpen?.find(data => data.Race === race && data.Activity === station)?.Amount ?? 0);
        SumTotalPerRace.Resolved += parseInt(raceStationResolved?.find(data => data.Race === race && data.Activity === station)?.Amount ?? 0);
        SumTotalPerRace.Total = SumTotalPerRace.Active + SumTotalPerRace.Resolved;
        SumTotalPerRace.Transport += parseInt(raceStationResolved?.find(data => data.Race === race && data.Activity === station)?.Transport ?? 0);

        SumTotalPerRace.Minor += parseInt(raceStationResolved?.find(data => data.Race === race && data.Activity === station)?.Minor ?? 0);
        SumTotalPerRace.Green += parseInt(raceStationResolved?.find(data => data.Race === race && data.Activity === station)?.Green ?? 0);
        SumTotalPerRace.Yellow += parseInt(raceStationResolved?.find(data => data.Race === race && data.Activity === station)?.Yellow ?? 0);
        SumTotalPerRace.Red += parseInt(raceStationResolved?.find(data => data.Race === race && data.Activity === station)?.Red ?? 0);
      })
      setStationSummaryTotal(prevValue => {
        return {...prevValue, [race]: SumTotalPerRace}});
     })
  }

  useEffect(() => {
    calculateMasterSummary();
  }, [])
  

  return (
    <div className="container" style={{"overflow-x": "auto"}}>
      <div class="row print header">
        <div className="row screen">
          <div className="col-md-12" style={{'margin-bottom':'30px'}}>
            <Form className="col-md-12" inline onSubmit={(e) => e.preventDefault()}>
              <FormGroup className="col-md-12" style={{padding:0}}>
                <textarea
                  id="filter-input"
                  value={reportTitle}
                  placeholder="Report Title"
                  onChange={(e) => setReportTitle(e.target.value)}
                  style={{width:'100%', fontSize:'20px'}}
                  className="form-control"
                  rows="4" cols="50"
                >
                </textarea>
              </FormGroup>
            </Form>
          </div>
        </div>
        <h2><strong className='summary-label'>{reportTitle}</strong></h2>
        <h3><strong className='summary-label'>{title}</strong></h3>
        <br/>
        <p style={{fontSize: 17}} >Report as of: {reports.time_initiated !== "" ? format(new Date(reports.time_initiated.replace(" ","T")), "LLL d, yyyy 'at' hh:mm a"): ""} </p>
        <p style={{fontSize: 17}}>Until {reports.end_time !== "" ? format(new Date(reports.end_time.replace(" ","T")), "LLL d, yyyy 'at' hh:mm a"): ""}  </p>
        <br/>
        <label className="screen">
          <input type="checkbox"
            defaultChecked={hideClosedRecords}
            onChange={() => setHideClosedRecords(!hideClosedRecords)}
          />
          Hide Closed Records
        </label>
      </div>
        
        {
          Object?.keys(reports?.races ?? []).length > 1 && (
            <div className="print master_summary_report_table">
              <br/>
              <br/>
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Total Unique Persons Seen</th>
                    <th scope="col">Total Incidents (Major + Minor)</th>
                    <th scope="col">Total Major Incidents</th>
                    <th scope="col">Major Incidents - Illness</th>
                    <th scope="col">Major Incidents - Injury</th>
                    <th scope="col">Total Minor Assistance</th>
                    {
                      !hideClosedRecords && (
                        <>
                          <th scope="col">Total Closed Records</th>
                          <th scope="col">Closed Records %</th>
                        </>
                      )
                    }
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span style={{fontWeight: 'bold'}}>{title}</span></td>
                    <td>{uniquePersonsTotal}</td>
                    <td>{injuryTotal + illnessTotal + minorTotal}</td>
                    <td>{injuryTotal + illnessTotal}</td>
                    <td>{injuryTotal}</td>
                    <td>{illnessTotal}</td>
                    <td>{minorTotal}</td>
                    {
                      !hideClosedRecords && (
                        <>
                          <td>{closedRecordsTotal}</td>
                          <td>{calculateClosedChartStat(minorTotal,(injuryTotal + illnessTotal),closedRecordsTotal)}%</td>
                        </>
                      )
                    }
                    
                  </tr>
                  {Object?.keys(dispositions?.dispositionTable ?? []).map((race,index) => (
                    <tr key={index}>
                      <td>{race}</td>
                      <td>{persons.find(r => r.Race === race)?.Amount ?? 0}</td>
                      <td>{(spots?.spotTable?.[race]?.['Injury']?.total ?? 0) + (spots?.spotTable?.[race]?.['Illness']?.total ?? 0) + (minorAssistance?.totals?.[race]?.total ?? 0)}</td>
                      <td>{(spots?.spotTable?.[race]?.['Injury']?.total ?? 0) + (spots?.spotTable?.[race]?.['Illness']?.total ?? 0)}</td>
                      <td>{(spots?.spotTable?.[race]?.['Illness']?.total ?? 0)}</td>
                      <td>{(spots?.spotTable?.[race]?.['Injury']?.total ?? 0)}</td>
                      <td>{minorAssistance?.totals?.[race]?.total ?? 0}</td>
                      {
                        !hideClosedRecords && (
                          <>
                            <td>{dispositions?.totals?.[race]?.total ?? 0}</td>
                            <td>{calculateClosedChartStat((minorAssistance?.totals?.[race]?.total ?? 0),
                            ((spots?.spotTable?.[race]?.['Injury']?.total ?? 0) + (spots?.spotTable?.[race]?.['Illness']?.total ?? 0)),
                            (dispositions?.totals?.[race]?.total ?? 0))}%</td>
                          </>
                        )
                      }
                      
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr/>
              <div className="master_summary_item_table">
                <br/>
                <br/>
                <table className={`table  table-responsive`}>
                    <thead>
                    <tr>
                      <th scope="col" style={countStyle}></th>
                      <th scope="col" style={countStyle}>Total</th>
                      {[...new Set([...Object?.keys(dispositions?.summaryTotals ?? []), 
                      ...Object?.keys(spots?.summaryTotals ?? []), 
                      ...Object?.keys(minorAssistance?.summaryTotals ?? []), 
                      ...Object?.keys(encounters?.summaryTotals ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                        <th scope="col" key={index}>{column}</th>
                      ))}
                    </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td><span style={{fontWeight: 'bold'}}>Total Unique Persons Seen</span></td>
                          <td>N/A</td>
                          {[...new Set([...Object?.keys(dispositions?.summaryTotals ?? []), ...Object?.keys(spots?.summaryTotals ?? []), ...Object?.keys(minorAssistance?.summaryTotals ?? []), ...Object?.keys(encounters?.summaryTotals ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                          <td>{encounters?.encounterSummaryTable?.["Unique"]?.[column]?? 0}</td>
                        ))}
                      </tr>
                      <tr>
                        <td><span style={{fontWeight: 'bold'}}>Total Incidents (Major + Minor)</span></td>
                        <td>{(spots?.summaryTotals?.total ?? 0) + (minorAssistance?.summaryTotals?.total ?? 0)}</td>
                        {[...new Set([...Object?.keys(spots?.summaryTotals ?? []), ...Object?.keys(spots?.summaryTotals ?? []), ...Object?.keys(minorAssistance?.summaryTotals ?? []), ...Object?.keys(encounters?.summaryTotals ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                          <td>{(spots?.summaryTotals?.[column]?? 0) + (minorAssistance?.encounterSummaryTable?.["Minor"]?.[column]?? 0)}</td>
                        ))}
                      </tr>
                      <tr>
                        <td><span style={{fontWeight: 'bold'}}>Total Major Incidents</span></td>
                        <td>{spots?.summaryTotals?.total}</td>
                        {[...new Set([...Object?.keys(spots?.summaryTotals ?? []), ...Object?.keys(spots?.summaryTotals ?? []), ...Object?.keys(minorAssistance?.summaryTotals ?? []), ...Object?.keys(encounters?.summaryTotals ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                          <td>{spots?.summaryTotals?.[column]?? 0}</td>
                        ))}
                      </tr>
                      {Object?.keys(spots?.spotSummaryTable ?? []).map((item,index) => (
                        <tr key={index}>
                          <td><span style={{fontWeight: 'bold'}}>Major Incidents - {item}</span></td>
                          <td>{spots?.spotSummaryTable?.[item].total}</td>
                          {[...new Set([...Object?.keys(dispositions?.summaryTotals ?? []), ...Object.keys(spots?.summaryTotals ?? []), ...Object?.keys(minorAssistance?.summaryTotals ?? []), ...Object?.keys(encounters?.summaryTotals ?? [])])]
                          .filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                          <td>{spots?.spotSummaryTable?.[item]?.[column]?? 0}</td>
                        ))}
                        </tr>
                      ))}
                      
                      
                      <tr>
                          <td><span style={{fontWeight: 'bold'}}>Total Minor Assistance</span></td>
                          <td>{minorAssistance?.summaryTotals?.total}</td>
                          {[...new Set([...Object?.keys(dispositions?.summaryTotals ?? []), ...Object?.keys(spots?.summaryTotals ?? []), ...Object?.keys(minorAssistance?.summaryTotals ?? []), ...Object?.keys(encounters?.summaryTotals ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                          <td>{minorAssistance?.encounterSummaryTable?.["Minor"]?.[column]?? 0}</td>
                        ))}
                      </tr>
                      {Object?.keys(dispositions?.dispositionSummaryTable ?? []).filter((item) => {return item.trim() !== 'Transported to Hospital'}).sort().map((item,index) => (
                        <tr key={index}>
                          <td><span style={{fontWeight: 'bold'}}>{item}</span></td>
                          <td>{dispositions?.dispositionSummaryTable?.[item]?.total}</td>
                          {[...new Set([...Object?.keys(dispositions?.summaryTotals ?? []), ...Object?.keys(spots?.summaryTotals ?? []), ...Object?.keys(minorAssistance?.summaryTotals ?? []), ...Object?.keys(encounters?.summaryTotals ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                          <td>{dispositions?.dispositionSummaryTable?.[item]?.[column]?? 0}</td>
                        ))}
                        </tr>
                      ))}
                      <tr>
                        <td><span style={{fontWeight: 'bold'}}>Transported to Hospital</span></td>
                        <td>{hospitals?.totals?.total}</td>
                        {[...new Set([...Object?.keys(dispositions?.summaryTotals ?? []), ...Object?.keys(spots?.summaryTotals ?? []), ...Object?.keys(minorAssistance?.summaryTotals ?? []), ...Object?.keys(encounters?.summaryTotals ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                          <td>{hospitals.totals[column]?? 0}</td>
                        ))}
                      </tr>
                      {
                        !hideClosedRecords && (
                          <tr>
                            <td><span style={{fontWeight: 'bold'}}>Total Closed Reports</span></td>
                            <td>{dispositions?.summaryTotals?.total}</td>
                            {[...new Set([...Object?.keys(dispositions?.summaryTotals ?? []), ...Object?.keys(spots?.summaryTotals ?? []), ...Object?.keys(minorAssistance?.summaryTotals ?? []), ...Object?.keys(encounters?.summaryTotals ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                              <td>{dispositions?.summaryTotals?.[column]?? 0}</td>
                            ))}
                          </tr>
                        )
                      }
                      
                    </tbody>
                  </table>
              </div>
              
            </div>
            
          )
        }
        
        <hr/>
        <div className="transport-label print">
          <br/>
          <br/>
          <p><strong className='summary-label'>Total Transports: {hospitals?.totals?.total ?? 0} </strong></p>
          <table className="table table-responsive hospital_report_table print">
            <thead>
              <tr>
                <th scope="col">Hospital</th>
                <th scope="col">Total</th>
                {Object?.keys(hospitals?.totals ?? []).filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                  <th scope="col" key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(Object.fromEntries(Object.entries(hospitals?.hospitalTable ?? []).sort((a, b) => b[1] - a[1]))).map((hospital,index) => (
                <tr key={index}>
                  <td><p>{hospital}</p></td>
                  <td>{hospitals.hospitalTable[hospital].total}</td>
                  {Object?.keys(hospitals?.totals ?? []).filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                  <td>{hospitals.hospitalTable?.[hospital]?.[column]?? 0}</td>
                ))}
                </tr>
              ))}
              <tr>
                <td>Total</td>
                <td>{hospitals?.totals?.total}</td>
                {Object?.keys(hospitals?.totals ?? []).filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                  <td>{hospitals.totals[column]?? 0}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        

        {Object?.keys(dispositions?.dispositionTable ?? []).map((disposition,index) => (
              < div className="print new-page" key={index} >
                <hr className="screen"/>
                <br className="print"/>
                <br/>
                <h3>{disposition}</h3>
                <p>
                  <strong className='summary-label'>Total Unique Persons Seen: {persons.find(r => r.Race === disposition)?.Amount ?? 0} | Total Incidents (Major + Minor) : {(spots?.spotTable?.[disposition]?.['Injury']?.total ?? 0)+ (spots?.spotTable?.[disposition]?.['Illness']?.total ?? 0) + (minorAssistance?.totals?.[disposition]?.total ?? 0)} | Total Major Incidents : {(spots?.spotTable?.[disposition]?.['Injury']?.total ?? 0)+ (spots?.spotTable?.[disposition]?.['Illness']?.total ?? 0)}| Injuries: {spots?.spotTable?.[disposition]?.['Injury']?.total ?? 0} | Illness: {spots?.spotTable?.[disposition]?.['Illness']?.total ?? 0} | Total Minor Assistance: {minorAssistance?.totals?.[disposition]?.total ?? 0} 
                  | Total Closed Records : {dispositions?.totals?.[disposition]?.total ?? 0}
                  </strong>
                </p>
                <table className={`table  table-responsive station_table${index}`}>
                  <thead>
                    {
                        !hideClosedRecords && (<tr><strong>Closed Records is {calculateClosedChartStat(minorAssistance?.totals?.[disposition]?.total ?? 0,(spots?.spotTable?.[disposition]?.['Injury']?.total ?? 0)+ (spots?.spotTable?.[disposition]?.['Illness']?.total ?? 0),dispositions?.totals?.[disposition]?.total ?? 0)}%</strong></tr>)
                    }
                    
                    <tr>
                      <th scope="col" style={countStyle}></th>
                      <th scope="col" style={countStyle}>Total</th>
                      {[...new Set([...Object?.keys(dispositions?.totals?.[disposition] ?? []), ...Object?.keys(spots?.totals?.[disposition] ?? []), ...Object?.keys(minorAssistance?.totals?.[disposition] ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                <th scope="col" key={index}>{column}</th>
              ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={index}>
                        <td><span style={{fontWeight: 'bold'}}>Total Unique Persons Seen</span></td>
                        <td>N/A</td>
                        {[...new Set([...Object?.keys(dispositions?.totals?.[disposition] ?? []), ...Object?.keys(spots?.totals?.[disposition] ?? []), ...Object?.keys(minorAssistance?.totals?.[disposition] ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                        <td>{encounters?.encounterTable?.[disposition]?.["Unique"]?.[column]?? 0}</td>
                      ))}
                    </tr>
                    <tr>
                      <td><span style={{fontWeight: 'bold'}}>Total Incidents (Major + Minor)</span></td>
                      <td>{(spots?.totals?.[disposition]?.total ?? 0) + (minorAssistance?.totals?.[disposition]?.total ?? 0)}</td>
                      {[...new Set([...Object?.keys(dispositions?.totals?.[disposition] ?? []), ...Object?.keys(spots?.totals?.[disposition] ?? []), ...Object?.keys(minorAssistance?.totals?.[disposition] ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                        <td>{(spots?.totals?.[disposition]?.[column]?? 0) + (minorAssistance?.encounterTable?.[disposition]?.["Minor"]?.[column] ?? 0)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td><span style={{fontWeight: 'bold'}}>Total Major Incidents</span></td>
                      <td>{spots?.totals?.[disposition]?.total}</td>
                      {[...new Set([...Object?.keys(dispositions?.totals?.[disposition] ?? []), ...Object?.keys(spots?.totals?.[disposition] ?? []), ...Object?.keys(minorAssistance?.totals?.[disposition] ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                        <td>{spots?.totals?.[disposition]?.[column]?? 0}</td>
                      ))}
                    </tr>
                    {Object?.keys(spots?.spotTable?.[disposition] ?? []).map((item,index) => (
                      <tr key={index}>
                        <td><span style={{fontWeight: 'bold'}}>Major Incidents - {item}</span></td>
                        <td>{spots?.spotTable?.[disposition][item].total}</td>
                        {[...new Set([...Object?.keys(dispositions?.totals?.[disposition] ?? []), ...Object.keys(spots?.totals?.[disposition] ?? []), ...Object?.keys(minorAssistance?.totals?.[disposition] ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                        <td>{spots?.spotTable?.[disposition]?.[item]?.[column]?? 0}</td>
                      ))}
                      </tr>
                    ))}
                    
                    <tr key={index}>
                        <td><span style={{fontWeight: 'bold'}}>Total Minor Assistance</span></td>
                        <td>{minorAssistance?.totals?.[disposition]?.total}</td>
                        {[...new Set([...Object?.keys(dispositions?.totals?.[disposition] ?? []), ...Object?.keys(spots?.totals?.[disposition] ?? []), ...Object?.keys(minorAssistance?.totals?.[disposition] ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                        <td>{minorAssistance?.encounterTable?.[disposition]?.["Minor"]?.[column]?? 0}</td>
                      ))}
                    </tr>
                    {Object?.keys(dispositions?.dispositionTable?.[disposition] ?? []).filter((item) => {return (disposition !== title) || (item.trim() !== 'Transported to Hospital')}).sort().map((item,index) => (
                      <tr key={index}>
                        <td><span style={{fontWeight: 'bold'}}>{item}</span></td>
                        <td>{dispositions?.dispositionTable?.[disposition]?.[item]?.total}</td>
                        {[...new Set([...Object?.keys(dispositions?.totals?.[disposition] ?? []), ...Object?.keys(spots?.totals?.[disposition] ?? []), ...Object?.keys(minorAssistance?.totals?.[disposition] ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                        <td>{dispositions?.dispositionTable?.[disposition]?.[item]?.[column]?? 0}</td>
                      ))}
                      </tr>
                    ))}
                    {
                      disposition === title && (
                        <tr>
                          <td><span style={{fontWeight: 'bold'}}>Transported to Hospital</span></td>
                          <td>{hospitals?.totals?.total}</td>
                          {[...new Set([...Object?.keys(dispositions?.totals?.[disposition] ?? []), ...Object?.keys(spots?.totals?.[disposition] ?? []), ...Object?.keys(minorAssistance?.totals?.[disposition] ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                            <td>{hospitals.totals[column]?? 0}</td>
                          ))}
                        </tr>
                      )
                    }
                    {
                        !hideClosedRecords && (
                          <tr>
                            <td><span style={{fontWeight: 'bold'}}>Total Closed Reports</span></td>
                            <td>{dispositions?.totals?.[disposition]?.total}</td>
                            {[...new Set([...Object?.keys(dispositions?.totals?.[disposition] ?? []), ...Object?.keys(spots?.totals?.[disposition] ?? []), ...Object?.keys(minorAssistance?.totals?.[disposition] ?? [])])].filter((item) => {return item !== 'total'}).sort(dateComparison).map((column,index) => (
                              <td>{dispositions?.totals?.[disposition]?.[column]?? 0}</td>
                            ))}
                          </tr>
                        )
                    }
                    
                  </tbody>
                </table>
                <br/>
                <table className={`table station_table_${index}`}>
                    <thead>
                      <tr>
                        <th scope="col" style={countStyle}> Medical Area</th>
                        <th scope="col" style={countStyle}>Total Major</th>
                        <th scope="col" style={countStyle}>Active/Open</th>
                        <th scope="col" style={countStyle}>{reports?.races?.[disposition]?.red?.name}</th>
                        <th scope="col" style={countStyle}>{reports?.races?.[disposition]?.yellow?.name}</th>
                        <th scope="col" style={countStyle}>{reports?.races?.[disposition]?.green?.name}</th>
                        <th scope="col" style={countStyle}>Minor</th>
                        <th scope="col" style={countStyle}>Resolved</th>
                        <th scope="col" style={countStyle}>Transport</th>
                      </tr>
                      <tr>
                        <th scope="col" style={countStyle}> Total</th>
                        <th scope="col" style={countStyle}>{stationSummaryTotal?.[disposition]?.Total}</th>
                        <th scope="col" style={countStyle}>{stationSummaryTotal?.[disposition]?.Active}</th>
                        <th scope="col" style={countStyle}>{stationSummaryTotal?.[disposition]?.Red}</th>
                        <th scope="col" style={countStyle}>{stationSummaryTotal?.[disposition]?.Yellow}</th>
                        <th scope="col" style={countStyle}>{stationSummaryTotal?.[disposition]?.Green}</th>
                        <th scope="col" style={countStyle}>{stationSummaryTotal?.[disposition]?.Minor}</th>
                        <th scope="col" style={countStyle}>{stationSummaryTotal?.[disposition]?.Resolved}</th>
                        <th scope="col" style={countStyle}>N/A</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object?.keys(reports?.races?.[disposition]?.statistics ?? []).map((station,index) => (
                        <>
                        <tr key={index}>
                          <td> <span style={{fontWeight: 'bold'}}>{station}</span></td>
                          <td>{parseInt(raceStationResolved?.find(data => data.Race === disposition && data.Activity === station)?.Amount ?? 0) 
                          + parseInt(raceStationOpen?.find(data => data.Race === disposition && data.Activity === station)?.Amount ?? 0)}</td>
                          <td>{raceStationOpen?.find(data => data.Race === disposition && data.Activity === station)?.Amount ?? 0}</td>
                          <td>{raceStationResolved?.find(data => data.Race === disposition && data.Activity === station)?.Red ?? 0}</td>
                          <td>{raceStationResolved?.find(data => data.Race === disposition && data.Activity === station)?.Yellow ?? 0}</td>
                          <td>{raceStationResolved?.find(data => data.Race === disposition && data.Activity === station)?.Green ?? 0}</td>
                          <td >
                            {raceStationResolved?.find(data => data.Race === disposition && data.Activity === station)?.Minor ?? 0}
                          </td>
                          <td >
                            {raceStationResolved?.find(data => data.Race === disposition && data.Activity === station)?.Amount ?? 0}
                          </td>
                          <td >
                            {raceStationResolved?.find(data => data.Race === disposition && data.Activity === station)?.Transport ?? 0}
                          </td>
                        </tr>
                        </>
                        

                      ))}
                    </tbody>
                </table>
              </div>
            ))}
        
        <br/>
    </div>
  );
};

export default DailyReportComponent;
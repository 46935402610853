import React, { useState, useEffect } from "react";
import DailyReportComponent from "./dailyReportComponent";
import generateDailyPDF from "../../utils/generateDailyReportPdf";
import axios from "axios";
import { format } from "date-fns";
import BounceLoader from "react-spinners/ClipLoader";


function totalComparison(a, b) {
  return a.total - b.total;
}

const formatHospital = (hospitals) => {
  let hospitalTable = [];
  let totals = {"total":0};
  for (const row of hospitals){
    //hospitalTable[row.Race][row.Hospital][row.TimeEventResolved] += parseInt(row.Amount);
    if(row.Hospital !== ""){
      
        //check for hospital
        if(hospitalTable[row.Hospital]){

          if(hospitalTable[row.Hospital][row.TimeEventResolved]){
            hospitalTable[row.Hospital][row.TimeEventResolved] += parseInt(row.Amount);
            hospitalTable[row.Hospital]["total"] += parseInt(row.Amount);
          }else{
            hospitalTable[row.Hospital]= {...hospitalTable[row.Hospital],[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)+hospitalTable[row.Hospital]["total"]};
          }

        }else{
          hospitalTable[row.Hospital] = {[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)};
        }

        //calculate the totals
        if(totals[row.TimeEventResolved]){
          totals = {...totals,[row.TimeEventResolved]:parseInt(row.Amount)+totals[row.TimeEventResolved], "total":parseInt(row.Amount)+parseInt(totals["total"])};
        }else{
          totals = {...totals,[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)+ parseInt(totals["total"])};
        }
        
    }
    
  }

  const sortedHospitalTable = Object.fromEntries(
    Object.entries(hospitalTable).sort((a, b) => b[1].total - a[1].total)
  );

  //console.log(totals);
  //console.log(hospitalTable.sort(totalComparison));
  return {
    hospitalTable: sortedHospitalTable,
    totals
  };
}

const formatDisposition = (dispositions) => {
  let dispositionTable = [];
  let dispositionSummaryTable = [];
  let totals = {};
  let summaryTotals = {};
  for (const row of dispositions){
    //hospitalTable[row.Race][row.Hospital][row.TimeEventResolved] += parseInt(row.Amount);
    if(dispositionTable[row.Race]){
        
        if(dispositionTable[row.Race][row.Disposition]){

          if(dispositionTable[row.Race][row.Disposition][row.TimeEventResolved]){
            dispositionTable[row.Race][row.Disposition][row.TimeEventResolved] += parseInt(row.Amount);
            dispositionTable[row.Race][row.Disposition]["total"] += parseInt(row.Amount);
          }else{
            dispositionTable[row.Race][row.Disposition]= {...dispositionTable[row.Race][row.Disposition],[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)+dispositionTable[row.Race][row.Disposition]["total"]};
          }

        }else{
          dispositionTable[row.Race][row.Disposition] = {[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)};
        }

        //calculate the totals
        if(totals[row.Race][row.TimeEventResolved]){
          totals[row.Race] = {...totals[row.Race],[row.TimeEventResolved]:parseInt(row.Amount)+totals[row.Race][row.TimeEventResolved], "total":parseInt(row.Amount)+totals[row.Race]["total"]};
        }else{
          totals[row.Race] = {...totals[row.Race],[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)+totals[row.Race]["total"]};
        }
    }else{
      dispositionTable[row.Race] = {[row.Disposition]:{[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)}}
      totals[row.Race] = {...totals[row.Race],[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)};
    }

    //parallel calculation for disposition as first key and not race, useful for summary report table
    if(dispositionSummaryTable[row.Disposition]){
      if(dispositionSummaryTable[row.Disposition][row.TimeEventResolved]){
        dispositionSummaryTable[row.Disposition][row.TimeEventResolved] += parseInt(row.Amount);
        dispositionSummaryTable[row.Disposition]["total"] += parseInt(row.Amount);
      }else{
        dispositionSummaryTable[row.Disposition]= {...dispositionSummaryTable[row.Disposition],[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)+dispositionSummaryTable[row.Disposition]["total"]};
      }

      //calculate the totals
      if(summaryTotals[row.TimeEventResolved]){
        summaryTotals = {...summaryTotals,[row.TimeEventResolved]:parseInt(row.Amount)+summaryTotals[row.TimeEventResolved], "total":parseInt(row.Amount)+summaryTotals["total"]};
      }else{
        summaryTotals = {...summaryTotals,[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)+summaryTotals["total"]};
      }
    }else{
      dispositionSummaryTable[row.Disposition] = {[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)}
      summaryTotals = {...summaryTotals,[row.TimeEventResolved]:parseInt(row.Amount) + (summaryTotals[row.TimeEventResolved] ?? 0), "total":parseInt(row.Amount)+(summaryTotals["total"] ?? 0)};
    }
  }
  dispositionTable = dispositionTable.sort(totalComparison)
  console.log(dispositionSummaryTable,summaryTotals)
  return {dispositionTable,totals,dispositionSummaryTable,summaryTotals};
}

const formatSpots = (spots) => {
  let spotTable = [];
  let totals = {};
  let spotSummaryTable = [];
  let summaryTotals = {};
  for (const row of spots){
    //hospitalTable[row.Race][row.Hospital][row.TimeEventResolved] += parseInt(row.Amount);
    if(spotTable[row.Race]){
        //check for hospital
        if(spotTable[row.Race][row.Spot]){

          if(spotTable[row.Race][row.Spot][row.TimeEventResolved]){
            spotTable[row.Race][row.Spot][row.TimeEventResolved] += parseInt(row.Amount);
            spotTable[row.Race][row.Spot]["total"] += parseInt(row.Amount);
          }else{
            spotTable[row.Race][row.Spot]= {...spotTable[row.Race][row.Spot],[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)+spotTable[row.Race][row.Spot]["total"]};
          }

        }else{
          spotTable[row.Race][row.Spot] = {[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)};
        }

        //calculate the totals
        if(totals[row.Race][row.TimeEventResolved]){
          totals[row.Race] = {...totals[row.Race],[row.TimeEventResolved]:parseInt(row.Amount)+totals[row.Race][row.TimeEventResolved], "total":parseInt(row.Amount)+totals[row.Race]["total"]};
        }else{
          totals[row.Race] = {...totals[row.Race],[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)+totals[row.Race]["total"]};
        }
    }else{
      spotTable[row.Race] = {[row.Spot]:{[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)}}
      totals[row.Race] = {...totals[row.Race],[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)};
    }


    if(spotSummaryTable[row.Spot]){
      if(spotSummaryTable[row.Spot][row.TimeEventResolved]){
        spotSummaryTable[row.Spot][row.TimeEventResolved] += parseInt(row.Amount);
        spotSummaryTable[row.Spot]["total"] += parseInt(row.Amount);
      }else{
        spotSummaryTable[row.Spot]= {...spotSummaryTable[row.Spot],[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)+spotSummaryTable[row.Spot]["total"]};
      }

      //calculate the totals
      if(summaryTotals[row.TimeEventResolved]){
        summaryTotals = {...summaryTotals,[row.TimeEventResolved]:parseInt(row.Amount)+summaryTotals[row.TimeEventResolved], "total":parseInt(row.Amount)+summaryTotals["total"]};
      }else{
        summaryTotals = {...summaryTotals,[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)+summaryTotals["total"]};
      }
    }else{
      spotSummaryTable[row.Spot] = {[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)}
      summaryTotals = {...summaryTotals,[row.TimeEventResolved]:parseInt(row.Amount) + (summaryTotals[row.TimeEventResolved] ?? 0), "total":parseInt(row.Amount)+(summaryTotals["total"] ?? 0)};
    }
  }
  //console.log(spotTable.sort(totalComparison),totals)
  return {spotTable,totals, spotSummaryTable, summaryTotals};
}

const formatUniqueEncounters = (encounters) => {
  let encounterTable = [];
  let totals = {};
  let unique = "Unique";
  let encounterSummaryTable = [];
  let summaryTotals = {};
  for (const row of encounters){
    //hospitalTable[row.Race][row.Hospital][row.TimeEventResolved] += parseInt(row.Amount);
    if(encounterTable[row.Race]){
        //check for hospital
        if(encounterTable[row.Race][unique]){

          if(encounterTable[row.Race][unique][row.TimeEventResolved]){
            encounterTable[row.Race][unique][row.TimeEventResolved] += parseInt(row.Amount);
            encounterTable[row.Race][unique]["total"] += parseInt(row.Amount);
          }else{
            encounterTable[row.Race][unique]= {...encounterTable[row.Race][unique],[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)+encounterTable[row.Race][unique]["total"]};
          }

        }else{
          encounterTable[row.Race][unique] = {[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)};
        }

        //calculate the totals
        if(totals[row.Race][row.TimeEventResolved]){
          totals[row.Race] = {...totals[row.Race],[row.TimeEventResolved]:parseInt(row.Amount)+totals[row.Race][row.TimeEventResolved], "total":parseInt(row.Amount)+totals[row.Race]["total"]};
        }else{
          totals[row.Race] = {...totals[row.Race],[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)+totals[row.Race]["total"]};
        }
    }else{
      encounterTable[row.Race] = {[unique]:{[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)}}
      totals[row.Race] = {...totals[row.Race],[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)};
    }


    if(encounterSummaryTable[unique]){
      if(encounterSummaryTable[unique][row.TimeEventResolved]){
        encounterSummaryTable[unique][row.TimeEventResolved] += parseInt(row.Amount);
        encounterSummaryTable[unique]["total"] += parseInt(row.Amount);
      }else{
        encounterSummaryTable[unique]= {...encounterSummaryTable[unique],[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount) + encounterSummaryTable[unique]["total"]};
      }

      //calculate the totals
      if(summaryTotals[row.TimeEventResolved]){
        summaryTotals = {...summaryTotals,[row.TimeEventResolved]:parseInt(row.Amount)+summaryTotals[row.TimeEventResolved], "total":parseInt(row.Amount)+summaryTotals["total"]};
      }else{
        summaryTotals = {...summaryTotals,[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)+summaryTotals["total"]};
      }
    }else{
      encounterSummaryTable[unique] = {[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)}
      summaryTotals = {...summaryTotals,[row.TimeEventResolved]:parseInt(row.Amount) + (summaryTotals[row.TimeEventResolved] ?? 0), "total":parseInt(row.Amount)+(summaryTotals["total"] ?? 0)};
    }
  }
  //console.log(encounterTable.sort(totalComparison),totals)
  return {encounterTable,totals, encounterSummaryTable, summaryTotals};
}

const formatRaceMinorAssistance = (minorAssistance) => {
  console.log(minorAssistance);
  let encounterTable = [];
  let totals = {};
  let unique = "Minor";
  let encounterSummaryTable = [];
  let summaryTotals = {};
  for (const row of minorAssistance){
    if(encounterTable[row.Race]){
        if(encounterTable[row.Race][unique]){

          if(encounterTable[row.Race][unique][row.TimeEventResolved]){
            encounterTable[row.Race][unique][row.TimeEventResolved] += parseInt(row.Amount);
            encounterTable[row.Race][unique]["total"] += parseInt(row.Amount);
          }else{
            encounterTable[row.Race][unique]= {...encounterTable[row.Race][unique],[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)+encounterTable[row.Race][unique]["total"]};
          }

        }else{
          encounterTable[row.Race][unique] = {[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)};
        }

        //calculate the totals
        if(totals[row.Race][row.TimeEventResolved]){
          totals[row.Race] = {...totals[row.Race],[row.TimeEventResolved]:parseInt(row.Amount)+totals[row.Race][row.TimeEventResolved], "total":parseInt(row.Amount)+totals[row.Race]["total"]};
        }else{
          totals[row.Race] = {...totals[row.Race],[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)+totals[row.Race]["total"]};
        }
    }else{
      encounterTable[row.Race] = {[unique]:{[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)}}
      totals[row.Race] = {...totals[row.Race],[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)};
    }


    if(encounterSummaryTable[unique]){
      if(encounterSummaryTable[unique][row.TimeEventResolved]){
        encounterSummaryTable[unique][row.TimeEventResolved] += parseInt(row.Amount);
        encounterSummaryTable[unique]["total"] += parseInt(row.Amount);
      }else{
        encounterSummaryTable[unique]= {...encounterSummaryTable[unique],[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount) + encounterSummaryTable[unique]["total"]};
      }

      //calculate the totals
      if(summaryTotals[row.TimeEventResolved]){
        summaryTotals = {...summaryTotals,[row.TimeEventResolved]:parseInt(row.Amount)+summaryTotals[row.TimeEventResolved], "total":parseInt(row.Amount)+summaryTotals["total"]};
      }else{
        summaryTotals = {...summaryTotals,[row.TimeEventResolved]:parseInt(row.Amount), "total":parseInt(row.Amount)+summaryTotals["total"]};
      }
    }else{
      encounterSummaryTable[unique] = {[row.TimeEventResolved]:parseInt(row.Amount),"total":parseInt(row.Amount)}
      summaryTotals = {...summaryTotals,[row.TimeEventResolved]:parseInt(row.Amount) + (summaryTotals[row.TimeEventResolved] ?? 0), "total":parseInt(row.Amount)+(summaryTotals["total"] ?? 0)};
    }
  }
  console.log(encounterTable.sort(totalComparison),totals)
  return {encounterTable,totals, encounterSummaryTable, summaryTotals};
}

const DailyReportPage = () => {
  const [reportTitle, setReportTitle] = useState(triageReportTitle ?? 'Daily Report')
  const [title, setTitle] = useState('');
  const [raceHospitals, setRaceHospitals] = useState([]);
  const [raceDisposition, setRaceDisposition] = useState([]);
  const [stations, setStations] = useState({});
  const [reports, setReports] = useState({});
  const [raceSpots, setRaceSpots] = useState([]);
  const [raceUniqueEncounters, setRaceUniqueEncounters] = useState([]);
  const [raceUniquePersons, setRaceUniquePersons] = useState([]);
  const [raceStationResolved, setRaceStationResolved] = useState([]);
  const [raceStationOpen, setRaceStationOpen] = useState([]);
  const [raceMinorAssistance, setRaceMinorAssistance] = useState([]);
  const [triageReportTitle, setTriageReportTitle] = useState('Daily Report');
  const [isLoading, setIsLoading] = useState(true);
  const [uniquePersonsTotal, setuniquePersonsTotal] = useState(0)
  const [closedRecordsTotal, setClosedRecordsTotal] = useState(0)
  const [injuryTotal, setInjuryTotal] = useState(0)
  const [illnessTotal, setIllnessTotal] = useState(0)
  const [minorTotal, setMinorTotal] = useState(0)

  const calculateMasterSummary = () => {
    Object?.keys(raceDisposition?.dispositionTable ?? []).forEach(element => {
      setuniquePersonsTotal(prevValue => {
        return prevValue + (raceUniqueEncounters?.totals?.[element]?.total ?? 0)});
      setClosedRecordsTotal(prevValue => {
        return prevValue + (raceDisposition?.totals?.[element]?.total ?? 0)});
      setInjuryTotal(prevValue => {
        return prevValue + (raceSpots?.spotTable?.[element]?.['Injury']?.total ?? 0)});
      setIllnessTotal(prevValue => {
        return prevValue + (raceSpots?.spotTable?.[element]?.['Illness']?.total ?? 0)});
      setMinorTotal(prevValue => {
        return prevValue + (raceMinorAssistance?.totals?.[element]?.total ?? 0)});
     })
     
  }

  useEffect(() => {
    calculateMasterSummary();
  }, [isLoading])

  useEffect(() => {
    axios.get(`${window.location.origin}/daily-report-api/${window.location.pathname.replace('/index.php', '').replace('/command-center/', '').split('/daily-report')[0]}?${window.location.href.split('?')?.[1]}`)
        .then((response) => {
            console.log(response);
            setTitle(response.data.raceTitle);
            setRaceHospitals(formatHospital(JSON.parse(response.data.raceHospitals)));
            setRaceDisposition(formatDisposition(JSON.parse(response.data.raceDisposition)));
            setRaceSpots(formatSpots(JSON.parse(response.data.raceSpots)));
            setRaceUniqueEncounters(formatUniqueEncounters(JSON.parse(response.data.raceUniqueEncounters)));
            setRaceUniquePersons(JSON.parse(response.data.raceUniquePersons));
            setRaceStationResolved(JSON.parse(response.data.raceStationResolved));
            setRaceStationOpen(JSON.parse(response.data.raceStationOpen));
            setStations(JSON.parse(response.data.stations));
            setReports(JSON.parse(response.data.reports));
            setTriageReportTitle(response.data.reportTitle);
            setIsLoading(false);
        })
        .catch(error => console.error('axios', error));
  }, []);
  const urlParams = new URLSearchParams(window.location.search);
  const startdate = urlParams.get('report[date]');
  const enddate = urlParams.get('report[endDate]');
  const fromDateTime = `${reports?.time_initiated && reports?.time_initiated !== "" ? format(new Date(reports?.time_initiated.replace(" ","T")), "LLL d, yyyy 'at' hh:mm a"): ""}`;
  const toDateTime = `${reports?.end_time && reports?.end_time !== "" ? format(new Date(reports?.end_time.replace(" ","T")), "LLL d, yyyy 'at' hh:mm a"): ""}`;
  if (!isLoading) {
    console.log('raceHospitals', raceHospitals);
    return (
        <DailyReportComponent triageReportTitle={triageReportTitle} title={title} 
              hospitals={raceHospitals} dispositions={raceDisposition} spots={raceSpots} encounters={raceUniqueEncounters} persons={raceUniquePersons} minorAssistance={raceMinorAssistance} reports={reports} raceStationOpen={raceStationOpen} raceStationResolved={raceStationResolved}/>
    );
  }

  return <div class="row">
    <div className="col-xs-6"></div>
    <div className="col-xs-6">
      <BounceLoader
        color={'#9da4cf'}
        loading={true}
        size={70}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  </div>;
};

export default DailyReportPage;